<template>
<div>
  <b-overlay :show="loadingPage" rounded="lg" opacity="0.7">
    <validation-observer ref="simpleRules">
      <div class="p-2 card">
        <b-row class="align-items-center">
          <h1 class="titulo-pagina ml-1">Questionário</h1>
        </b-row>
        <b-row>
          <span class="default-font-properties ml-1"> Você pode editar as informações de cadastro do questionário, assim como as questões e alternativas. </span>
        </b-row>
        <b-row v-if="mostrarAlertaCopia" class="mt-2">
          <div class="custom-space-alert">
            <b-alert
              show
              variant="success"
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="AlertTriangleIcon"
                />
                <span class="ml-25"><b>Edição salva com sucesso!</b> Ao salvar e habilitar para uso será criada uma nova versão deste questionário</span>
              </div>
            </b-alert>
          </div>
        </b-row>
        <b-row class="mt-2">
          <b-col md="6" class="mb-1">
            <validation-provider #default="{ errors }" rules="required">
              <b-form-group
                label="Nome do Questionário*"
                label-for="input-nome"
              >
                <b-form-input
                  v-model="questionario.descricao"
                  :state="errors.length > 0 ? false : null"
                  id="input-nome"
                  placeholder="(máx. 50 caracteres)"
                  maxlength="50"
                  autocomplete="off"
                  v-on:focusout="verificaNomeEmUso()"
                  @keyup="mostrarAlertaCopia=true"
                />
                <small v-if="errors.length > 0" class="text-danger">
                  Campo Obrigatório
                </small>
                <small v-if="erroNomeQuestionario" class="text-danger">
                  O nome escolhido já está em uso. Por favor, escolha um diferente.
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6" class="mb-1">
            <validation-provider #default="{ errors }" rules="required">
              <b-form-group
                label="Descrição*"
                label-for="input-descricao"
              >
                <b-form-input
                  v-model="questionario.descricao_tecnica"
                  :state="errors.length > 0 ? false : null"
                  id="input-descricao"
                  placeholder="(máx. 115 caracteres)"
                  maxlength="115"
                  autocomplete="off"
                  @keyup="mostrarAlertaCopia=true"
                />
                <small v-if="errors.length > 0" class="text-danger">
                  Campo Obrigatório
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="mt-2 mb-1">
          <b-col lg="0" class="ml-1">
            <div>
              <b-form-radio
                v-model="questionario.usarFeedback"
                name="some-radios"
                :value="true"
                class="title-radio-custom"
              >
                Utilizar feedback
              </b-form-radio>
            </div>
          </b-col>
          <b-col >
            <div>
              <b-form-radio
                v-model="questionario.usarFeedback"
                name="some-radios"
                :value="false"
                class="title-radio-custom"
              >
                Não utilizar feedback
              </b-form-radio>
            </div>
          </b-col>
        </b-row>
      </div>


      <!--BEGIN CARD EDIÇÃO-->
      <div class="p-2 card" v-if="mostrarEdicao">
        <b-row class="align-items-center">
          <b-col class="d-flex">
            <h5 class="question text-center">Questão {{mostraQuestao+1}}</h5>
            <span class="ml-1 text-center default-font-properties qtd-questions"> - {{mostraQuestao+1}} de {{ questionario.questoes.length}} questões </span>
          </b-col>
          <b-col align="right">
            <b-button
              type="submit"
              variant="custom"
              class="mr-1 btn-sm"
              @click.prevent="salvarQuestaoEditada()"
            >
              <span>
                Salvar
              </span>
            </b-button>
          </b-col>
        </b-row>
        <!-- PERCORRE AS QUESTÕES -->
        <div v-for="(questao, index) in questionario.questoes" :key="index">
          <div v-if="index== mostraQuestao">
            <div class="mt-3 ml-1 mb-1">
              <b-row>
                <b-form-checkbox
                  v-model="questao.tipoSelecao.selecaoMultipla"
                  @change="trocaTipoSelecao('selecaoMultipla', index)"
                />
                <small> Seleção múltipla </small>
                <b-form-checkbox
                  v-model="questao.tipoSelecao.multiplaEscolha"
                  class="ml-1"
                  @change="trocaTipoSelecao('multiplaEscolha', index)"
                />
                  <small> Escolha única </small>
              </b-row>
              <b-row v-if="!questao.tipoSelecao.selecaoMultipla && !questao.tipoSelecao.multiplaEscolha">
                <small class="text-danger mt-1"> Selecione uma opção </small>
              </b-row>
              <b-row v-if="questionario.usarFeedback">
                <b-col lg="12" align="right" class="d-flex flex-row-reverse">
                  <small
                    class="cursor-pointer"
                    @click="infoPontuacao()"
                  >
                    Peso total da questão: 36
                    <feather-icon
                      class="info-pontuacao cursor-pointer"
                      icon="InfoIcon"
                      @click="infoPontuacao()"
                    />
                  </small>
                </b-col>
              </b-row>
            </div>
            <validation-provider #default="{ errors }" rules="required">
              <b-input-group class="mt-0">
                <template #append>
                  <b-input-group-text
                    v-if="questionario.questoes.length>1"
                    @click.prevent="removeQuestao(index)"
                    class="cursor-pointer"
                    :disabled="questionario.questoes.length > 1 ? false : true"
                    :style="errors.length > 0 ? 'border: 1px solid red' : null"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      size="14"
                    />
                  </b-input-group-text>
                </template>
                <b-form-input
                  v-model="questionario.questoes[index].enunciado"
                  placeholder="Digite aqui o enunciado* (máx. 115 caracteres)"
                  autocomplete="off"
                  maxlength="115"
                  class="placeholder-bold"
                  :style="errors.length > 0 ? 'border: 1px solid red' : null"
                />
              </b-input-group>
              <small v-if="errors.length > 0" class="text-danger ml-1">
                Campo Obrigatório
              </small>
            </validation-provider>
            <!-- PERCORRE AS ALTERNATIVAS -->
            <div v-for="(alternativa, indice) in questao.alternativas" :key="indice">
              <!-- MOSTRA CAMPOS REFERENTES AO FEEDBACK -->
              <div v-if="questionario.usarFeedback">
                <validation-provider #default="{ errors }" rules="required">
                  <b-input-group class="mt-1">
                    <template #append>
                      <b-input-group-text
                        @click.prevent="indice >= 2 &&  removeAlternativa(mostraQuestao,indice)"
                        :disabled="indice <= 2 ? true: false"
                        class="cursor-pointer"
                        style="border: none;"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          size="14"
                        />
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      v-model="alternativa.opcao"
                      autocomplete="off"
                      class="placeholder-black mr-1 rounded width-input-custom"
                      maxlength="115"
                      :style="errors.length > 0 ? 'border: 1px solid red;' : ''"
                    />
                    <b-form-input
                      v-model="alternativa.peso"
                      class="rounded"
                      :style="errors.length > 0 || alternativa.peso == 0 ? 'border: 1px solid red; width:5%;' : 'width:5%;'"
                      type="number"
                      placeholder="Peso"
                      maxlength="20"
                      autocomplete="off"
                      @keyup="somaPontuacaoTotal(index)"
                    />
                  </b-input-group>
                  <small v-if="errors.length > 0" class="text-danger ml-1">
                    Campo Obrigatório
                  </small>
                  <b-col align="right">
                    <small v-if="errors.length && alternativa.peso == 0"  class="text-danger mr-1">
                      Valor Inválido
                    </small>
                  </b-col>
                </validation-provider>
              </div>
              <!-- MOSTRA CAMPOS PARA QUESTÕES SEM FEEDBACK -->
              <div v-else>
                <validation-provider #default="{ errors }" rules="required">
                  <b-input-group class="mt-1" id="teste">
                    <template #append>
                      <b-input-group-text
                          @click.prevent="indice >= 2 && removeAlternativa(mostraQuestao,indice)"
                          :disabled="indice < 2"
                          class="cursor-pointer"
                          :style="errors.length > 0 ? { 'border': '1px solid red' } : null"
                      >
                          <feather-icon
                              icon="Trash2Icon"
                              size="14"
                          />
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      v-model="alternativa.opcao"
                      autocomplete="off"
                      maxlength="115"
                      class="placeholder-black"
                      :style="errors.length > 0 ? 'border: 1px solid red' : null"
                    />
                  </b-input-group>
                  <small v-if="errors.length > 0" class="text-danger ml-1">
                    Campo Obrigatório
                  </small>
                </validation-provider>
              </div>
            </div>
             <small v-if="questionario.usarFeedback && questao.pontuacaoTotal != 36" class="text-danger ml-1"><br>
              Soma total da pontuação é diferente de 36.
            </small><br/>
            <small class="custom-information"> Mínimo de 2 e máximo de 8 alternativas </small>
            <div class="mt-1">
              <b-button
                type="submit"
                variant="custom"
                class="min-button"
                @click.prevent="questao.alternativas.length <= 7 && insereAlternativa(index)"
                :disabled="questao.alternativas.length <= 7 ? false : true"
              >
                +
              </b-button>
              <small class="placeholder-black ml-custom">
                Inserir mais alternativas
              </small>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-3 mb-1">
          <button
            class="c-paginator-arrows mr-1"
            @click="voltar()"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              size="14"
            />
          </button>
          <div
            v-for="number in displayedNumbers"
            :key="number"
          >
            <button
              :class="mostraQuestao+1 == number ? 'c-paginator-arrows-reverse' : 'c-paginator-arrows-none' "
              class="mr-1"
              @click="irParaQuestao(number)"
            >{{ number }}</button>

          </div>
          <button
            class="c-paginator-arrows"
            @click="proximo()"
          >
            <feather-icon
              icon="ChevronRightIcon"
              size="14"
            />
          </button>
        </div>
      </div>
      <!--END CARD EDIÇÃO-->


      <div class="p-2 card" v-if="!mostrarEdicao">
        <b-row>
          <b-col class="d-flex align-items-center">
            <h5 class="question text-center">Revise as questões cadastradas</h5>
          </b-col>
          <b-col align="right">
            <b-button
              type="submit"
              variant="outline-primary"
              class="mr-1 btn-sm"
              @click.prevent="editar()"
            >
              <span>
                <feather-icon
                  icon="Edit3Icon"
                  size="14"
                />
                Editar
              </span>
            </b-button>
            <b-button
              type="submit"
              variant="outline-primary"
              class="btn-sm"
              @click.prevent="inserirQuestao()"
            >
              <span>
                <feather-icon
                  icon="PlusSquareIcon"
                  size="14"
                />
                Inserir questão
              </span>
            </b-button>
          </b-col>
        </b-row>
        <b-alert
          v-height-fade
          show
          dismissible
          fade
          class="mt-1"
          variant="primary"
        >
          <div class="alert-body">
            <feather-icon
              icon="AlertCircleIcon"
              size="14"
              class="mr-1"
            />
            <span>Aqui você pode revisar as questões cadatradas no questionário e editá-las.</span>
          </div>
        </b-alert>
        <div class="mt-1">
          <app-collapse>
            <div class="border-collapse">
              <b-row
                :aria-expanded="collapseVisible.visibleOptionsQuestion ? 'true' : 'false'"
                aria-controls="collapse-options-question"
                class="mt-1 mb-1 collapsed"
                @click="collapseVisible.visibleOptionsQuestion = !collapseVisible.visibleOptionsQuestion"
              >
                <b-col lg="10" class="ml-1">
                  <h5
                    v-if="mostraQuestao>=0"
                    class="title-question"
                  >
                    <strong> {{questionario.questoes[mostraQuestao].enunciado}} </strong>
                  </h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12">
                  <b-collapse
                    id="collapse-options-question"
                    v-model="collapseVisible.visibleOptionsQuestion"
                    class="default-font-properties options-question"
                  >
                    <b-card>
                      <div
                        v-for="(alternativa, x) in questionario.questoes[mostraQuestao].alternativas"
                        :key="x"
                      >
                        <span> {{labelAlternativa[x+1]+')'+' '+alternativa.opcao}} </span>
                      </div>
                    </b-card>
                  </b-collapse>
                </b-col>
              </b-row>
            </div>
          </app-collapse>
          <b-row>
            <b-col
              lg="4"
              class="d-flex align-items-center"
            >
              <span
                class="qtd-questions default-font-properties mt-2"
              >
                Mostrando {{ mostraQuestao+1 }} de {{ questionario.questoes.length }} resultados
              </span>
            </b-col>
            <b-col lg="8">
              <div class="d-flex justify-content-end mt-3 mb-1">
                <button
                  class="c-paginator-arrows mr-1"
                  @click="voltar()"
                >
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="14"
                  />
                </button>
                <div
                  v-for="number in displayedNumbers"
                  :key="number"
                >
                  <button
                    :class="mostraQuestao+1 == number ? 'c-paginator-arrows-reverse' : 'c-paginator-arrows-none' "
                    class="mr-1"
                    @click="irParaQuestao(number)"
                  >
                    {{ number }}
                  </button>
                </div>
                <button
                  class="c-paginator-arrows"
                  @click="proximo()"
                >
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="14"
                  />
                </button>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>

      <div class="p-1 card">
        <b-row class="margin-custom-mobile" v-if="!questionario.usarFeedback">
          <b-col align="left" class="d-flex">
            <b-button
              type="submit"
              variant="outline-primary"
              @click.prevent="exportarQuestionario()"
            >
              <span>
                <feather-icon
                  icon="DownloadIcon"
                  size="14"
                />
                <span v-if="mostrarTextoBotao" class="space-beetwen-button-text"> Exportar </span>
              </span>
            </b-button>
          </b-col>
          <b-col class="d-flex justify-content-end" align="right">
            <b-button
              type="submit"
              variant="outline-primary"
              class="mr-1"
              @click.prevent="cancelar()"
            >
              <span>
                Cancelar
              </span>
            </b-button>
            <b-button
              type="submit"
              variant="custom"
              class="mr-1"
              @click.prevent="salvar()"
            >
              <span>
                Salvar e habilitar
              </span>
            </b-button>
          </b-col>
        </b-row>
        <b-col class="d-flex justify-content-for-mobile align-custom-buttons" v-else>
          <b-button
            v-if="!inverterCancelar"
            type="submit"
            variant="outline-primary"
            class="mr-1 margin-btn-custom"
            @click.prevent="cancelar()"
          >
            <span>
              Cancelar
            </span>
          </b-button>
          <b-button
            v-if="questionario.usarFeedback"
            type="submit"
            variant="custom"
            class="mr-1"
            @click.prevent="irParaFeedbacks()"
          >
            <span>
              Próximo
            </span>
          </b-button>
           <b-button
            v-if="inverterCancelar"
            type="submit"
            variant="outline-primary"
            class="mr-1 mt-1 margin-btn-custom"
            @click.prevent="cancelar()"
          >
            <span>
              Cancelar
            </span>
          </b-button>
        </b-col>
      </div>
      <div class="mt-1"><span class="p-1"> </span>
      </div>
    </validation-observer>
  </b-overlay>
</div>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
  import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
  import { required } from "@validations";
  import { heightFade } from '@core/directives/animations';
  import Ripple from 'vue-ripple-directive';
  import {
    modalGenericModel
  } from '@/libs/sweetalerts';
  import {
    BFormTextarea,
    BCardText,
    BCardBody,
    BCard,
    BCardHeader,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BCollapse,
    BFormRadio,
    BInputGroup,
    BInputGroupText,
    BInputGroupAppend,
    BFormGroup,
    BImg,
    BAlert,
    BFormCheckbox
  } from "bootstrap-vue";

  export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    AppCollapse,
    AppCollapseItem,
    required,
    BFormTextarea,
    BCardText,
    BCardBody,
    BCard,
    BCardHeader,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BCollapse,
    BFormRadio,
    BInputGroup,
    BInputGroupText,
    BInputGroupAppend,
    BFormGroup,
    BImg,
    BAlert,
    BFormCheckbox
  },

  directives: {
    heightFade,
    Ripple,
  },

  data() {
    return {
      loadingPage: true,
      mostrarEdicao: false,
      totalNumbers: 0,
      currentIndex: 1,
      mostraQuestao: 0,
      inverterCancelar: true,
      mostrarTextoBotao: true,
      mostrarAlerta: false,
      mostrarAlertaCopia: false,
      situacao: '',
      erroNomeQuestionario: false,
      questionario: {
        idQuestionario: null,
        descricao: '',
        descricao_tecnica: '',
        usarFeedback: null,
        qtdQuestoes: null,
        em_uso: null,
        questoes: [{
          qtdAlternativas: 2,
          pontuacaoTotal: 0,
          enunciado: null,
          tipoSelecao: {
            selecaoMultipla: false,
            multiplaEscolha: false
          },
          alternativas: [
            {
              opcao: null,
              peso: null
            },
            {
              opcao: null,
              peso: null
            }
          ]
        }],
        feedbacks: [],
      },
      pontuacao: {
        minimo: 0,
        maximo: 0,
        intervaloA: 0,
        intervaloB: 0,
        intervaloC: 0,
        intervaloD: 0,
        intervaloE: 0,
        intervaloF: 0,
        intervaloG: 0,
        intervaloH: 0,
      },
      labelAlternativa:{
        1: 'a',
        2: 'b',
        3: 'c',
        4: 'd',
        5: 'e',
        6: 'f',
        7: 'g',
        8: 'h',
      },
      collapseVisible: {
        visibleOptionsQuestion: true,
        1: true,
        2: false,
        3: false,
        4: false,
      }
    };
  },

  computed: {
    displayedNumbers(){
      this.totalNumbers = this.questionario.questoes.length;
      const numbers = [];
      const numToShow = Math.min(7, this.totalNumbers);
      for (let i = 0; i < numToShow; i++) {
        let number = this.currentIndex + i;
        if (number > this.totalNumbers) {
          number -= this.totalNumbers;
        }
        numbers.push(number);
      }
      return numbers;
    }
  },

  async mounted(){
    await this.carregaQuestionario();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.loadingPage = false;
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  props: {
    questionarioData: {
      type: Object,
      required: true
    },
  },

  methods: {
    handleResize() {
      this.mostrarTextoBotao = window.innerWidth >= 760;
      if(window.innerWidth <= 500){
        this.inverterCancelar = true;
      }else{
        this.inverterCancelar = false;
      }
    },

    async carregaQuestionario(){
      if(!this.questionarioData){
        this.$router.push({ name: 'listagem-questionarios'});
      }else{
        this.questionario.idQuestionario    = this.questionarioData.idQuestionario;
        this.questionario.descricao         = this.questionarioData.descricao;
        this.questionario.descricao_tecnica = this.questionarioData.descricao_tecnica ;
        this.questionario.usarFeedback      = this.questionarioData.feedback.length ? true : false;
        this.questionario.qtdQuestoes       = this.questionarioData.qtdQuestoes;
        this.questionario.questoes          = this.questionarioData.questoes.reverse();
        this.questionario.feedbacks         = this.questionarioData.feedback ? this.questionarioData.feedback : [];
        this.questionario.situacao          = 'Ativo';
        this.questionario.em_uso            = this.questionarioData.em_uso;
        this.loadingPage = false;
      }
    },

    formataDadosQuestionario(){
      this.questionario.qtdQuestoes = this.questionario.questoes.length;
      for(let questoes of this.questionario.questoes){
        questoes.pontuacaoTotal = 36;
      }
      return {
        idQuestionario:    this.questionario.idQuestionario,
        descricao:         this.questionario.descricao,
        descricao_tecnica: this.questionario.descricao_tecnica,
        situacao:          this.questionario.situacao,
        usar_feedback:     this.questionario.usarFeedback ,
        qtdQuestoes:       this.questionario.qtdQuestoes,
        questoes:          this.questionario.questoes,
        feedback:          this.questionario.usarFeedback ? this.questionario.feedbacks : [],
        em_uso:            this.questionario.em_uso,
      };
    },

    inserirQuestao(){
      if(this.questionario.questoes.length<50){
        let novaQuestao =  {
          enunciado: '',
          pontuacaoTotal: 0,
          qtdAlternativas: 2,
          tipoSelecao: {
            selecaoMultipla: null,
            multiplaEscolha: null
          },
          alternativas: [
            {
              opcao: '',
              peso: 0
            },
            {
              opcao: '',
              peso: 0
            }
          ]
        };
        this.questionario.questoes.push(novaQuestao);
      }
      this.mostraQuestao = this.questionario.questoes.length-1;
      this.mostrarEdicao = true;
    },

    trocaTipoSelecao(tipoSelecao, index){
      if(tipoSelecao === 'selecaoMultipla'){
        this.questionario.questoes[index].tipoSelecao.selecaoMultipla = true;
        this.questionario.questoes[index].tipoSelecao.multiplaEscolha = false;
      }else if(tipoSelecao === 'multiplaEscolha'){
        this.questionario.questoes[index].tipoSelecao.selecaoMultipla = false;
        this.questionario.questoes[index].tipoSelecao.multiplaEscolha = true;
      }else{
        this.questionario.questoes[index].tipoSelecao.selecaoMultipla = null;
        this.questionario.questoes[index].tipoSelecao.multiplaEscolha = null;
      }
    },

    removeAlternativa(questao, alternativa){
      if(this.questionario.questoes[questao].alternativas.length > 2){
        this.questionario.questoes[questao].alternativas.splice(alternativa, 1);
        this.somaPontuacaoTotal(questao);
      }
    },

    irParaQuestao(indice){
      this.mostraQuestao = indice-1;
    },

    voltar(){
      if(this.mostraQuestao > 0){
        if(this.currentIndex>1){
          this.currentIndex--;
        }
        this.mostraQuestao--;
      }else{
        this.currentIndex = this.questionario.questoes.length;
        this.mostraQuestao = this.currentIndex-1;
      }
    },

    proximo(){
      if(this.mostraQuestao+1 < this.questionario.questoes.length){
        this.currentIndex++;
        this.mostraQuestao++;
      }else{
        this.currentIndex = 1;
        this.mostraQuestao = 0;
      }
    },

    salvarQuestaoEditada(){
      this.loadingPage = true;
      let vazio = false;
      for(let questao of this.questionario.questoes){
        for(let alternativa of questao.alternativas){
          if(
            !questao.enunciado ||
            !alternativa.opcao
          ){
            vazio = true;
          }
        }
        if(
          questao.tipoSelecao.selecaoMultipla == null ||
          questao.tipoSelecao.multiplaEscolha == null
        ){
          vazio = true;
        }
      }
      if(this.questionario.usarFeedback){
        if(this.verificaPontuacao()  || this.verificaPeso()){
          vazio = true;
        }
      }

      this.verificaSalvamentoQuestaoEditadaVazia(vazio)
    },

    verificaSalvamentoQuestaoEditadaVazia(vazio){
      if(vazio){
        modalGenericModel(
          'Confira o preenchimento correto dos campos',
          '',
          require('@/assets/custom-icons/cora-icons/warning.png'),
          'Ok'
        )
        this.loadingPage = false;
      }else{
        if(this.questionarioData.em_uso){
          this.mostrarAlertaCopia = true;
        }
        modalGenericModel(
          'Sucesso!',
          'Sua atualização no questionário foi salva com sucesso!',
          require('@/assets/custom-icons/cora-icons/success.png'),
          'Ok'
        )
        this.mostrarEdicao = false;
        this.loadingPage = false;
      }
    },

    verificaPontuacao(){
      let soma = 0;
      for(let questao of this.questionario.questoes){
        for(let alternativa of questao.alternativas){
          soma = soma + parseInt(alternativa.peso);
        }
        if(soma != 36){
          return true;
        }
        soma = parseInt(0);
      }
      return false;
    },

    verificaPeso(){
      for(let questao of this.questionario.questoes){
        for(let alternativa of questao.alternativas){
          if(!alternativa.peso || alternativa.peso == 0){
            return true;
          }
        }
      }
      return false;
    },

    somaPontuacaoTotal(n){
      let total = 0;
      for(let alternativa of this.questionario.questoes[n].alternativas){
        if(alternativa.peso != null){
          total = parseInt(total) + parseInt(alternativa.peso);
        }
      };
      this.questionario.questoes[n].pontuacaoTotal = total;
    },

    insereAlternativa(i){
      if(this.questionario.questoes[i].alternativas.length<=7){
        let opcao = {opcao:null};
        this.questionario.questoes[i].alternativas.push(opcao);
        this.questionario.questoes[i].qtdAlternativas++;
      }
    },

    editar(){
      this.mostrarEdicao = !this.mostrarEdicao;
    },

    removeQuestao(questao){
      if(this.questionario.questoes.length>1){
        this.questionario.questoes[questao].enunciado = '';
        this.questionario.questoes[questao].pontuacaoTotal = 0;
        this.questionario.questoes[questao].feedback = [{valorInicial: null,valorFinal: null,mensagem: null}];
        this.questionario.questoes[questao].tipoSelecao = {selecaoMultipla: false,multiplaEscolha: false};
        this.questionario.questoes[questao].alternativas = [{opcao: '',peso: null},{opcao: '',peso: null}];
        this.questionario.questoes.splice(questao,1);
        this.mostraQuestao--;
        this.currentIndex = this.mostraQuestao+1;
      }
      if(questao==0){
        this.proximo();
      }
    },

    habilitarFeedback(){
      this.questionario.usarFeedback = true;
    },

    exportarQuestionario(){
      modalGenericModel(
        'Exportar Questionário',
        'Ao exportar o questionário você poderá revisar todas as informações que cadastrou. ' +
        'Você pode deixar seu questionário salvo como rascunho enquanto isso!',
        require('@/assets/custom-icons/cora-icons/download.png'),
        'Entendi'
      ).then((result) => {
        if (result.isConfirmed) {
          this.baixarQuestionario();
        }else{
          return ;
        }
      });
    },

    async baixarQuestionario(){
      this.loadingPage = true;
      let questionario = this.formataDadosQuestionario();
      await this.$http.post(this.$api.questionario.exportarRespostasPdf, questionario, {responseType: "blob"})
        .then(response => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(response.data);
          link.download = questionario.descricao+'.pdf';
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          modalGenericModel(
            error.response.data.error,
            '',
            require('@/assets/custom-icons/cora-icons/warning.png'),
            'Entendi'
          )
        });
      this.loadingPage = false;
    },

    desabilitarFeedback(){
      this.questionario.feedback = [];
      this.questionario.usarFeedback = false;
    },

    cancelar(){
      modalGenericModel(
        'Cancelar',
        'Você tem certeza que deseja cancelar e perder as alterações cadastradas?',
        require('@/assets/custom-icons/cora-icons/warning.png'),
         'Sim, cancelar',
         'Não, continuar editando'
      ).then((result) => {
        if (result.isConfirmed) {
          this.$router.push({ name: 'listagem-questionarios' });
        }else{
          return ;
        }
      });
    },

    salvar(){
      if(this.questionarioData.em_uso){
        modalGenericModel(
          'Salvar nova versão',
          'Este questionário já foi utilizado em uma aplicação e só poderá ser salvo como uma nova versão. Deseja continuar?',
          require('@/assets/custom-icons/cora-icons/warning.png'),
          'Continuar',
          'Cancelar'
        ).then((result) => {
          if (result.isConfirmed) {
            this.inserirEUsar();
          }
        });
      }else{
        modalGenericModel(
          'Salvar e Habilitar',
          'Ao salvar e habilitar para uso, o questionário ficará na lista de questionários criados ativos e você poderá criar uma aplicação a partir dele.',
          require('@/assets/custom-icons/cora-icons/success.png'),
          'Sim, prosseguir',
          'Cancelar'
        ).then((result) => {
          if (result.isConfirmed) {
            this.inserirEUsar();
          }
        });
      }
    },


    async irParaFeedbacks(){
      this.loadingPage = true;

      await this.verificaNomeEmUso();

      if(this.erroNomeQuestionario){
        modalGenericModel(
          'Confira o preenchimento correto dos campos',
          '',
          require('@/assets/custom-icons/cora-icons/warning.png'),
          'Ok'
        )
        this.loadingPage = false;
      }else{
        let questionario = this.formataDadosQuestionario();
        this.$router.push({ name: 'editar-feedback', params: { questionarioData: questionario }});
        this.loadingPage = false;
      }
    },

    verificaNomeEmUso(){
      this.erroNomeQuestionario = false;
      let rota =  this.$helpers.preparaRotaComMultiplosParametros(
                    this.$api.questionario.verificaNomeExisteComId,
                    {
                        "{nomeQuestionario}": this.questionario.descricao,
                        "{idQuestionario}": this.questionario.idQuestionario,
                    }
                  );
      return this.$http
              .get(rota)
              .then((res) => {
                if (res.status !== 200) {
                  this.erroNomeQuestionario = true;
                }else{
                  if(this.questionarioData.em_uso){
                    this.mostrarAlertaCopia = true;
                  }
                }
              })
              .catch((error) => {
                if (error.response.status === 400) {
                  this.erroNomeQuestionario = true;
                }
              });
    },

    async inserirEUsar(){
      this.loadingPage = true;
      let valorValido = false;

      for(let feedbacks of this.questionario.feedbacks){
        if(
          feedbacks.valorInicial < this.pontuacao.minimo ||
          feedbacks.valorInicial > this.pontuacao.maximo ||
          feedbacks.valorFinal < this.pontuacao.minimo ||
          feedbacks.valorFinal > this.pontuacao.maximo
        ){
          valorValido = true;
        }
      }

      await this.verificaNomeEmUso();
      if(this.erroNomeQuestionario){
        this.loadingPage = false;
        return;
      }else{
        if(this.questionario.usarFeedback && valorValido){
          modalGenericModel(
            'Atenção',
            'Confira o preenchimento correto da pontuação e dos textos de feedback antes de finalizar.',
            require('@/assets/custom-icons/cora-icons/warning.png'),
            'Entendi'
          );
          this.pontuacao.valorValido = true;
        }else{
          this.loadingPage = true;
          let questionario = this.formataDadosQuestionario();
          await this.$http.put(this.$api.questionario.atualizar, questionario)
            .then(() => {
              modalGenericModel(
                'Sucesso!',
                'Sua atualização no questionário foi salva com sucesso!',
                require('@/assets/custom-icons/cora-icons/success.png'),
                'Entendi'
              ).then(() => {
                this.$router.push({ name: 'listagem-questionarios' })
              })
            })
            .catch((error) => {
              modalGenericModel(
                error.response.data.error,
                '',
                require('@/assets/custom-icons/cora-icons/warning.png'),
                'Entendi'
              );
            });
        }
      }
      this.loadingPage = false;
    },

    infoPontuacao(){
      modalGenericModel(
        'Incluir pontuação',
        'É necessário incluir a pontuação em todas as alternativas' +
        ' cadastradas antes de continuar. A pontuação de todas as' +
        ' alternativas somadas deve ser igual a 36',
        require('@/assets/custom-icons/info-gray.svg'),
        'Entendi',
        null,
        true
      );
    },

    verificaMostrarPrimeiroWarning(novoValor){
      if(novoValor){
        modalGenericModel(
          'Utilizar feedbacks?',
          'Você criou um questionário “sem feedback” e deseja alterar para “com feedback”,' +
          ' portanto, será necessário incluir a pontuação (peso) em cada uma das alternativas.' +
          ' Deseja prosseguir com a edição?',
          require('@/assets/custom-icons/cora-icons/warning.png'),
          'Sim, prosseguir',
          'Cancelar'
        ).then((result) => {
          if (result.isConfirmed) {
            this.habilitarFeedback();
            this.mostrarEdicao = true;
          }else{
            this.mostrarPrimeiroWarning = false;
            this.desabilitarFeedback();
          }
        });
      }else{
        modalGenericModel(
          'Remover feedbacks?',
          'Você criou um questionário “com feedback” e deseja alterar para “sem feedback”,' +
          ' ao remover o uso de feedbacks do questionário, as pontuações cadastradas serão perdidas.' +
          ' Deseja prosseguir com a edição?',
          require('@/assets/custom-icons/cora-icons/warning.png'),
          'Sim, prosseguir',
          'Cancelar'
        ).then((result) => {
          if (result.isConfirmed) {
            this.desabilitarFeedback();
          }else{
            this.mostrarPrimeiroWarning = false;
            this.habilitarFeedback();
          }
        });
      }
    }
  },

  watch: {
    'questionario.usarFeedback': function (novoValor) {
      if(!this.mostrarPrimeiroWarning){
        if(novoValor){
          this.habilitarFeedback();
        }else{
          this.desabilitarFeedback();
        }
        this.mostrarPrimeiroWarning = true;
      }else{
        this.verificaMostrarPrimeiroWarning(novoValor)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .default-font-properties{
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.313rem;
    letter-spacing: 0px;
  }
  .question{
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.375rem;
    letter-spacing: 0px;
  }
  .title-question{
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
    letter-spacing: 0px;
  }
  .options-question{
    color: #6E6B7B;
  }
  .border-collapse{
    border: 1px solid #D8D6DE;
    border-radius: 5px;
    box-shadow: 0px 4px 24px 0px #0000000F;
  }
  .ml-custom{
    margin-left: 0.438rem;
    color: #7030A0;
  }
  .width-custom{
    width: 1.625rem;
    height: 1.625rem;
    padding: 0;
    text-align: center;
    margin-right: 0.325rem;
  }
  .title-radio-custom{
    font-size: 0.938rem !important;
  }
  .info-pontuacao{
    width: 0.875rem;
    height: 0.875rem;
    margin-left: 0.275rem;
    margin-bottom: 0.150rem;
  }
  .placeholder-bold{
    font-weight: 600 !important;
  }
  .min-button{
    width: 1.625rem;
    height: 1.625rem;
    padding: 0;
    margin-top: 0!important;
    text-align: center;
  }
  .custom-space-alert{
    width: 100%;
    margin: 0 auto;
  }
  .c-paginator-arrows{
    border: none;
    color: #FFFFFF;
    text-decoration: none;
    width: 28px;
    height: 28px;
    background: #7030A0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .c-paginator-arrows-reverse{
    text-decoration: none;
    width: 32px;
    height: 32px;
    border: 2px solid #7030A0;
    border-radius: 50%;
    background-color: transparent;
    color: #7030A0;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .c-paginator-arrows-none{
    text-decoration: none;
    width: 28px;
    height: 28px;
    border: 2px solid transparent;
    border-radius: 50%;
    background-color: transparent;
    color: #7030A0;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .col-img-cora{
    text-align: right;
    @media(max-width: 1500px){
      margin-right: 2rem;
    }
    @media (max-width: 990px) {
      text-align: center;
    }
  }
  .col-msg-cora{
    text-align: left;
    align-items: left;
    @media (max-width: 990px) {
      text-align: center;
    }
  }
  .row-msg-cora{
    @media (max-width: 990px) {
      display: flex;
      justify-content: center;
    }
  }
  .custom-margin-top-cora{
    margin-top: 1.5rem;
  }
  .titulo-pagina{
    font-weight: 500;
    font-size: 1.625rem;
    line-height: 1.375rem;
    letter-spacing: 0px;
    margin-bottom: 1.25rem;
  }
  .custom-information{
    font-size: 0.825rem;
    font-weight: 400;
    line-height: 15px;
    color: #B9B9C3;
  }
  .custom-margin-top{
    margin-top: 0.375rem !important;
    background: black;
  }
  .margin-custom-mobile{
    @media (max-width: 596px) {
      margin-bottom: 1rem;
    }
  }
  .space-beetwen-button-text{
    margin-left: 8px;
  }
  .justify-content-for-mobile{
    justify-content: end;
    @media (max-width: 596px) {
      justify-content: start;
      margin-left: 1rem;
    }
  }
  .width-input-custom{
    width: 85%;
    @media (max-width: 990px) {
      width: 60%;
    }
  }
  .align-custom-buttons{
    display: flex;
    justify-content: flex-end;
    @media (max-width: 500px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
  .margin-btn-custom{
    @media (max-width: 500px) {
      margin-bottom: 8px;
    }
  }
</style>
