export
const validatorPositive = value => {
    return value >= 0;
}

const strClear = str => str.replace(/\D/g, '')

export const validatorPassword = password => {
    /* eslint-disable no-useless-escape */
    const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
        /* eslint-enable no-useless-escape */
    return regExp.test(password)
}

export const validatorCreditCard = creditnum => {
    /* eslint-disable no-useless-escape */
    const cRegExp = /^(?:3[47]\d{13})$/
        /* eslint-enable no-useless-escape */
    return cRegExp.test(creditnum)
}

export const validatorUrlValidator = val => {
    if (val === undefined || val === null || val.length === 0) {
        return true
    }
    /* eslint-disable no-useless-escape */
    const re = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}\.?/
    /* eslint-enable no-useless-escape */
    return re.test(val)
}

export const validatorRendaBruta = valor => {
    return valor >= 0 && valor <= 150000;
}

export const validatorCPF = cpf => {
  cpf = strClear(cpf)
  if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
  var result = true;
  [9, 10].forEach(function(j) {
    var soma = 0,
    r;
    cpf
      .split(/(?=)/)
      .splice(0, j)
      .forEach(function(e, i) {
        soma += parseInt(e) * (j + 2 - (i + 1));
      });
    r = soma % 11;
    r = r < 2 ? 0 : 11 - r;
    if (r != cpf.substring(j, j + 1)) result = false;
  });
  return result;
}

export const validatorCNPJ = doc => {
  doc = strClear(doc)
  if(doc.length !== 14) return false
  if (!doc) return false
  if (doc.length !== 14) return false
  // Elimina docs invalidos conhecidos
  if (
    doc === "00000000000000" ||
    doc === "11111111111111" ||
    doc === "22222222222222" ||
    doc === "33333333333333" ||
    doc === "44444444444444" ||
    doc === "55555555555555" ||
    doc === "66666666666666" ||
    doc === "77777777777777" ||
    doc === "88888888888888" ||
    doc === "99999999999999"
  )
    return false;
  // Valida DVs
  let size = doc.length - 2
  let nums = doc.substring(0, size)
  const dig = doc.substring(size)
  let sum = 0
  let pos = size - 7
  for (let i = size; i >= 1; i--) {
    sum += nums.charAt(size - i) * pos--
    if (pos < 2) pos = 9
  }
  let res = sum % 11 < 2 ? 0 : 11 - sum % 11
  if (res != dig.charAt(0)) return false
  size += 1
  nums = doc.substring(0, size)
  sum = 0
  pos = size - 7
  for (let i = size; i >= 1; i--) {
    sum += nums.charAt(size - i) * pos--
    if (pos < 2) pos = 9
  }
  res = sum % 11 < 2 ? 0 : 11 - sum % 11
  return res == dig.charAt(1)
}
